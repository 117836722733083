/**
 * Main home page
 *
 * 
 * @file   Main home page
 * @author LeanCTO
 * @since  1.0.0
 * @copyright (c) 2022 All rights reserved.
 * 
 */
<template>
    <v-container id="container" fill-height fluid class="ma-0" :class="{ smAndDown: $vuetify.breakpoint.smAndDown}">
        <v-row class="text-center">
            <v-col cols="12">
                <div v-for="(item, index) in items" :key="index" :class="{ 'my-8': $vuetify.breakpoint.smAndDown}">
                    <span class="code" :class="{ current: currentCode === item.code, notrevealed: !item.isRevealed, revealed: item.isRevealed }">{{item.code}}</span>

                    <!-- Revealed Item -->
                    <a v-if="item.isRevealed" class="item" :class="{ 'item-current': currentCode === item.code, notrevealed: !item.isRevealed, revealed: item.isRevealed  }" @click.stop="load(item)" :ref="(currentCode === item.code ? 'current' : '')">
                        {{ item.revealDate | date_output }} ▪ {{item.postRevealTitle}}
                    </a>

                    <!-- Not-revealed, but has a pre-reveal title -->
                    <a v-else-if="!item.isRevealed && item.preRevealTitle" @click.stop="load(item)" class="item" :class="{ 'item-current': currentCode === item.code, notrevealed: !item.isRevealed, revealed: item.isRevealed }">
                        <Countdown :title="item.preRevealTitle" :date="item.revealDate" :current="currentCode === item.code" class="countdown" :class="{ current: currentCode === item.code }">{{item.postRevealTitle}}</Countdown>
                    </a>

                    <!-- Not-revealed, no pre-veal title -->
                    <a v-else-if="!item.isRevealed && !item.preRevealTitle" @click.stop="load(item)" class="item" :class="{ 'item-current': currentCode === item.code, notrevealed: !item.isRevealed, revealed: item.isRevealed }">
                        <Countdown :date="item.revealDate" :current="currentCode === item.code" class="countdown" :class="{ current: currentCode === item.code }">{{item.postRevealTitle}}</Countdown>
                    </a>
                </div>
            </v-col>
        </v-row>

        <!-- The model we popup when an item is clicked -->
        <Modal :show="showModal" :item="item" @close="closeModal">
            <template #body>
                <router-view :item="item" />
            </template>
        </Modal>
    </v-container>
</template>

<script>
// import the modal pages we're going to show here
import Modal from './Modal.vue';

// Resuable components
import Countdown from '../components/CountdownTimer.vue';

// Library to help us scroll to the current element
const VueScrollTo = require('vue-scrollto');

// The config containing all the codes
import codes from '../router/codes';

// Keep track of an interval to update our data
let tickInterval = null;

export default {
    name: 'HomeView',
    
    data() {
        return {
            // For loading the popup
            showModal: false,
            item: null,

            // The items we're showing on the front page
            tickInterval: null,
            cancelScroll: null,
            scrollTimer: null,
            currentCode: '',
            items: codes,
        }
    },

    components: {
        Modal,
        Countdown,
    },

    // Custom output functions
    filters: {
        "date_output": (value) => {
            if (!value || !value.length) {
                return '??????????';
            }

            const options = { year: 'numeric', month: 'short', day: '2-digit' };
            const d = Math.trunc(Date.parse(value));
            return new Date(d).toLocaleDateString('en-NZ', options);
        },
    },

    beforeMount() {
        this.onTick();
    },
    
    mounted() {
        // If we've come in on a sub-route, load the popup for that route
        if (this.$route.path) {
            const code = '#' + this.$route.path.substring(1);
            const index = this.items.findIndex( i => i.code === code);
            if (index !== -1) {
                this.load(this.items[index]);
            }
        }
        
        // Start updating teh countdown timers
        tickInterval = window.setInterval(() => {
            this.onTick();
        }, 1000);
    },

    unmount() {
        if (tickInterval) {
            clearInterval(tickInterval);
        }
    },

    methods: {
        load(item) {
            // Goto new route if it's defined
            if (item.route) {
                this.showModal = true;
                this.item = item;
                
                if (this.$route.path !== item.route) {
                    this.$router.push({ path: item.route, params: { item } });
                }
            }
        },

        closeModal() {
            this.showModal = false;
            this.item = null;
            
            // Reset the router back
            if (this.$route.path) {
                this.$router.replace('/');
            }
        },

        onTick() {
            // Since the data does not change when the time changes, we need to update the data array each tick to make sure we detect when to reveal an item
            this.items.forEach((element, index) => {
                const now = Math.trunc((new Date()).getTime() / 1000);
                const reveal = Math.trunc(Date.parse(element.revealDate) / 1000);
                this.$set(this.items[index], 'isRevealed', reveal <= now);
            });

            // Calc which is the current item and mark it as such
            const min = this.items.reduce((previousValue, currentValue) => {
                // convert both into times
                const dateNow = Math.trunc((new Date()).getTime() / 1000);
                const prevTs = Math.trunc(Date.parse(previousValue.revealDate) / 1000);
                const prevRevealed = prevTs <= dateNow;
                return prevRevealed ? currentValue : previousValue;
            });

            // If the current code has changed, we scroll the window to it
            if (min.code && min.code !== this.currentCode) {
                this.scrollTimer = setTimeout(() => {
                    // Clear any existing timer
                    if (this.scrollTimer) {
                        clearTimeout(this.scrollTimer);
                    }

                    // Setup scroll options
                    const scrollOptions = {
                        container: 'body',
                        easing: 'ease-in',
                        offset: -150,
                        force: true,
                        cancelable: true,
                        x: false,
                        y: true
                    }
                    const el = document.getElementsByClassName('item-current');
                    if (el && el[0]) {
                        this.cancelScroll = VueScrollTo.scrollTo(el[0], 0.5, scrollOptions);
                    }
                }, 500);
            }
            
            // Set the new current code
            this.currentCode = min ? min.code : '';
        }
    }
}
</script>


<style scoped>
@import url('https://fonts.googleapis.com/css?family=Big+Shoulders+Display:400,600,700');
.row {
    z-index: 3;    
}

.code {
    font-size: 40px;
    color: #5271FF;
    margin-right: 20px;
    font-family: 'Big Shoulders Display', sans-serif;
}

.code.revealed {
    color: #004AAD;
    font-size: 40px;
}

.code.revealed.current {
    font-size: 45px;
}

.code.notrevealed {
    color: #5CE1E6;
    font-size: 40px;
}

.code.notrevealed.current {
    color: #5271FF;
    font-size: 45px;
}

.item {
    font-family: 'Big Shoulders Display', sans-serif;
    font-size: 40px;
    color: #545454;
    /*width: 800px;*/
    display: inline-block;
    font-weight: 600;
    text-transform: uppercase;
    text-decoration: none;
    transition: .25s;
}

.item.item-current {
    font-size: 45px;
    color: #ffffff;
    font-weight: 700;
}

.countdown {
    /*min-width: 150px;*/
    display: inline-block;
    text-align: left;
    transition: .25s;
    text-align: center;
}

.countdown.current {
    /*min-width: 200px;*/
    text-align: center;
}

a.item:hover {
    color: #5CE1E6;
}

a.item.revealed:hover {
    color: #004AAD;
}

a.item.item-current.notrevealed {
    color: #5271FF;
}

a span.countdown:hover {
    color: #5CE1E6;
}

a.item.item-current.notrevealed span.countdown:hover {
    color: #5271FF;
}

/* Small Browser Sizes */
.smAndDown .code, .smAndDown .item {
    font-size: 30px;
}

.smAndDown .code.revealed.current, .smAndDown .code.notrevealed.current, .smAndDown .item.item-current {
    font-size: 35px;
}
</style>