/**
 * Configuration for the various codes and pages they link to
 *
 * 
 * @file   Main application codes and routes
 * @author LeanCTO
 * @since  1.0.0
 * @copyright (c) 2022 All rights reserved.
 * 
 */

const items = [
    {
        code: '#000',
        revealDate: 'June 15, 2022 17:30:00',                    // Use following exact format for date: June 8, 2023 17:00:00 or leave blank for ?????????? - COMING SOON
        preRevealTitle: '',                                     // if empty, it will show the date or use this as a teaser
        postRevealTitle: 'POSITIVE SUM x PERFECT LOUDNESS',     // shown after the date has been revealed
        route: '/000',
    },
    {
        code: '#001',
        revealDate: 'September 6, 2022 07:00:00',               // Use following exact format for date: June 8, 2023 17:00:00 or leave blank for ?????????? - COMING SOON
        preRevealTitle: 'POSITIVE SUM x SUNRISE AOTEAROA',               // if empty, it will show the date or use this as a teaser
        postRevealTitle: 'POSITIVE SUM x SUNRISE AOTEAROA',     // shown after the date has been revealed
        route: '/001',
    },
];

export default items;