var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{staticStyle:{"background":"black"}},[_c('div',{staticClass:"video-wrapper"},[_c('video',{ref:"video",attrs:{"autoplay":"autoplay","muted":"muted","playsinline":"","loop":"loop","preload":"auto"},domProps:{"muted":true}},[_c('source',{attrs:{"src":require('./assets/pexels-jorge-urosa-7762075.mp4'),"type":"video/mp4"}})]),_c('div',{staticClass:"overlay"}),_c('div',{staticClass:"home-content"},[_c('v-app-bar',{attrs:{"app":"","color":"transparent","dark":"","extended":"","extension-height":"20","fixed":"","flat":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"logo mt-5",style:({ 'background-color': hover ? '#5CE1E6' : '#BB86FC' }),attrs:{"large":"","color":"#ffd700","width":"50","height":"50","to":{ name: 'aboutUs' }}},[_vm._v("+Σ")])]}}])}),_c('v-spacer'),(!_vm.$vuetify.breakpoint.smAndDown)?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"text-h6 mt-5",style:({ 'color': hover ? '#5CE1E6' : '#BB86FC' }),attrs:{"text":"","large":"","height":"50"},on:{"click":_vm.openFoundingPaper}},[_vm._v("Founding Paper")])]}}],null,false,2167204446)}):_vm._e(),(!_vm.$vuetify.breakpoint.smAndDown)?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"text-h6 mt-5",style:({ 'color': hover ? '#5CE1E6' : '#BB86FC' }),attrs:{"text":"","large":"","height":"50"},on:{"click":_vm.openDaoDocs}},[_vm._v("DAO Docs")])]}}],null,false,1723083166)}):_vm._e(),(!_vm.$vuetify.breakpoint.smAndDown)?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"text-h6 mt-5",style:({ 'color': hover ? '#5CE1E6' : '#BB86FC' }),attrs:{"text":"","large":"","height":"50"},on:{"click":_vm.openDiscord}},[_c('img',{attrs:{"src":require(("./assets/discord" + (hover ? '_hover' : '') + ".png")),"height":"30"}})])]}}],null,false,1970299554)}):_vm._e(),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-app-bar-nav-icon',{staticClass:"mr-0 mt-5",style:({ 'color': hover ? '#5CE1E6' : '#BB86FC' }),on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}})]}}],null,false,3703338160)}):_vm._e()],1),_c('v-main',{staticStyle:{"padding-top":"0","height":"100vh"}},[_c('router-view')],1)],1),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-navigation-drawer',{staticStyle:{"background-color":"rgba(38,50,56,0.95)"},attrs:{"permanent":false,"app":"","mini-variant":"","mini-variant-width":"60%"},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{staticClass:"pb-0"},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"text-h6 mt-5 text-left",style:({ 'color': hover ? '#5CE1E6' : '#BB86FC' }),attrs:{"text":"","large":"","height":"50"},on:{"click":_vm.openFoundingPaper}},[_vm._v("Founding Paper")])]}}],null,false,4115375861)})],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_c('v-list-item-title',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"text-h6 mt-5 text-left",style:({ 'color': hover ? '#5CE1E6' : '#BB86FC' }),attrs:{"text":"","large":"","height":"50"},on:{"click":_vm.openDaoDocs}},[_vm._v("DAO Docs")])]}}],null,false,3926490677)})],1)],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"text-h6 mt-5 text-left",style:({ 'color': hover ? '#5CE1E6' : '#BB86FC' }),attrs:{"text":"","large":"","height":"50"},on:{"click":_vm.openDiscord}},[_c('img',{attrs:{"src":require(("./assets/discord" + (hover ? '_hover' : '') + ".png")),"height":"30"}})])]}}],null,false,2919942345)})],1)],1)],1)],1)],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }