<template>
    <v-container id="container" aligm="center" justify="center">
        <v-row class="text-center">
            <v-col cols="12">
                <!-- Title -->
                <h3>Guerilla Sunrise ｜ +Σ Coffee Collective</h3>
                <p v-if="!isRevealed">Join us ahead of the Sunrise Aoteoroa event in Auckland, New Zealand and share some IRL vibes</p>
    
                <!-- Call to action button -->
                <v-btn class="app-button" v-if="isRevealed" disabled>event ended</v-btn>
                <v-btn class="app-button" v-else @click="openTicketUrl">join us</v-btn>

                <!-- sign up form -->
            </v-col>
        </v-row>
        <v-row class="text-center" align="center" justify="center">
            <v-spacer />
            <v-col :cols="getCols">
                <!-- newsletter sign up -->
                <div v-if="isRevealed">
                    <p>
                        <span><a href="http://eepurl.com/hSEMVP" target="_blank">IYKYK</a> - loop in</span>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span><a href="https://discord.gg/3dhddwGSMf" target="_blank">IYRKYRK</a> - go deep</span>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span>💥 +1 to our +Σ 💥</span>
                    </p>
                    
                </div>
                
                <!-- details form -->
                <v-form ref="form" v-if="isRevealed && !success" class="mt-15" v-model="valid" @submit.prevent="submit" autocomplete="off">
                    <v-text-field v-model="firstname" placeholder="firstname..." clearable solo class="mt-2" :rules="firstnameRules" />
                    <v-text-field v-model="lastname" placeholder="lastname..." clearable solo class="mt-2" :rules="lastnameRules" />
                    <v-text-field v-model="email" placeholder="email..." clearable solo class="mt-2" :rules="emailRules" />
                    <v-text-field v-model="wallet" placeholder="web3 wallet address (optional)..." clearable solo class="mt-2" append-icon="mdi-help-circle-outline" @click:append="openWalletHelp" />

                    <!-- Submit -->
                    <v-btn class="app-button" type="submit" :disabled="!valid" :loading="loading">claim poap</v-btn>

                    <!-- Error Message -->
                    <div ref="error" class="errormessage" v-show="errorMessage" v-html="errorMessage"></div>
                </v-form>

                <!-- Success message -->
                <div v-if="isRevealed && success">
                    <div class="successmessage">
                        You're in, hang tight!<br />
                    </div>
                </div>
            </v-col>
            <v-spacer />
        </v-row>
    </v-container>
</template>

<script>
// Common imports
import axios from 'axios';

// Library to help us scroll to the current element
const VueScrollTo = require('vue-scrollto');

export default {
    name: 'PageCode001',

    props: {
        item: Object,
    },

    data() {
        return {
            // Form fields
            firstname: '',
            lastname: '',
            email: '',
            wallet: '',

            // Form submit state
            valid: false,
            success: false,
            loading: false,

            // Error Message
            errorMessage: '',

            // Validation Rules
            firstnameRules: [
                v => !!v || 'First name is required',
            ],

            lastnameRules: [
                v => !!v || 'Last name is required',
            ],

            emailRules: [
                v => !!v || 'Email address is required',
                v => /.+@.+/.test(v) || 'Email address must be valid',
            ],
        }
    },

    computed: {
        isRevealed() {
            if (!this.item || !this.item.revealDate) return;
            const now = Math.trunc((new Date()).getTime() / 1000);
            const reveal = Math.trunc(Date.parse(this.item.revealDate) / 1000);
            return reveal <= now;
        },

        getCols() {
            return this.$vuetify.breakpoint.smAndDown ? 12 : 8;
        }
    },

    methods: {
        openTicketUrl() {
            window.open("https://www.eventbrite.com/e/positive-sum-x-sunrise-aotearoa-tickets-406601354597");
        },
        
        openWalletHelp() {
            window.open("https://www.creatorbread.com/blog/how-to-set-up-a-metamask-wallet-step-by-step-guide");
        },

        submit() {
            // Cancel submit if form invalid
            if (!this.$refs.form.validate()) {
                return;
            }

            // Clear message
            this.errorMessage = ''

            // Process a valid form submit
            this.loading = true;

            // Create the axios object to post to our URL
            const remote = axios.create({ baseURL: `${process.env.VUE_APP_BASE_SUBMIT_URL}`});
            remote.post('001.php', {
                firstname: this.firstname,
                lastname: this.lastname,
                email: this.email,
                wallet: this.wallet,
            })
            .then(() => {
                this.success = true;
            })
            .catch((error) => {
                if (error.response && error.response.status === 400) {
                    this.errorMessage = "Hmm, that doesn't look right...";
                } else {
                    this.errorMessage = 'Oops, something went wrong! <br /><a href="mailto:dan@0.ventures?subject=%23001&amp;body=First%20name%3A%20%20%20Last%20name%3A%20%20%20Email%3A%20%20%20Web3%20Wallet%20Address%3A">Email us instead</a>';
                }
                
                // Setup scroll options
                const scrollOptions = {
                    container: 'body',
                    easing: 'ease-in',
                    offset: -150,
                    force: true,
                    cancelable: true,
                    x: false,
                    y: true
                }
                
                // Scroll to the error
                this.$nextTick(()=>VueScrollTo.scrollTo(this.$refs.error, 0.5, scrollOptions));

                // Timer to hide the error
                setTimeout(()=>this.errorMessage = '', 5000);

            }).finally(() => this.loading = false)
        }
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Big+Shoulders+Display:400,600,700');
h3 {
    font-size: 30px;
    color: #ffffff;
    font-family: 'Big Shoulders Display', sans-serif;
    font-weight: 600;
    margin-bottom: 20px;
}

.successmessage {
    color: #C9E265;
    margin: 200px 0 0 0;
    font-family: 'Big Shoulders Display', sans-serif;
    font-weight: 700;
    font-size: 30px;
}

.v-btn.app-button {
    background-color: #5CE1E6;
    font-family: 'Big Shoulders Display', sans-serif;
    font-weight: 700;
    font-size: 20px;
    text-transform: lowercase;
}

.v-btn.v-btn--disabled.v-btn--has-bg.app-button {
    background-color: rgba(92, 225, 230, 0.7) !important;
}

.v-btn.v-btn--disabled.app-button {
    color: rgba(0,0,0,0.3) !important;
}

.v-input.v-text-field {
    font-size: 20px;
    font-family: 'Big Shoulders Display', sans-serif;
    font-weight: 700;
}
</style>
<style>
.v-text-field.v-text-field--solo .v-input__control input {
    color: #000F54;
}

.errormessage, .errormessage a, .v-application .errormessage a {
    color: #FF5757;
    margin-top: 20px;
    font-family: 'Big Shoulders Display', sans-serif;
    font-weight: 400;
    font-size: 30px;
}

.errormessage a:hover{
    color: white;
}
</style>