/**
 * Include and configure the vue-meta plugin
 *
 * @file   Plugin include file for vue-meta
 * @author LeanCTO
 * @since  1.0.0
 * @copyright (c) 2022 All rights reserved.
 * 
 */
import Vue from 'vue';
import VueMeta from 'vue-meta';

Vue.use(VueMeta);

export default VueMeta;
