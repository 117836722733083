/**
 * Front-end view controller for the main application page
 *
 * This is a container view for all other application pages
 * 
 * @file   Main application navigation router configuration
 * @author LeanCTO
 * @since  1.0.0
 * @copyright (c) 2022 All rights reserved.
 * 
 */
 <template>
    <v-app style="background: black">
        <div class="video-wrapper">
            <video autoplay="autoplay" muted="muted" playsinline="" loop="loop" preload="auto" ref="video">
                <source :src="require('./assets/pexels-jorge-urosa-7762075.mp4')" type="video/mp4">
            </video>

            <div class="overlay"></div>

            <div class="home-content">
                <!-- The menu bar -->
                <v-app-bar app color="transparent" dark extended extension-height="20" fixed flat>
                    <v-hover v-slot="{ hover }">
                        <v-btn large color="#ffd700" class="logo mt-5" width="50" height="50" :style="{ 'background-color': hover ? '#5CE1E6' : '#BB86FC' }" :to="{ name: 'aboutUs' }">+Σ</v-btn>
                    </v-hover>
                    <v-spacer></v-spacer>
                    <v-hover v-slot="{ hover }" v-if="!$vuetify.breakpoint.smAndDown">
                        <v-btn text large class="text-h6 mt-5" height="50" :style="{ 'color': hover ? '#5CE1E6' : '#BB86FC' }" @click="openFoundingPaper">Founding Paper</v-btn>
                    </v-hover>
                    <v-hover v-slot="{ hover }" v-if="!$vuetify.breakpoint.smAndDown">
                        <v-btn text large class="text-h6 mt-5" height="50" :style="{ 'color': hover ? '#5CE1E6' : '#BB86FC' }" @click="openDaoDocs">DAO Docs</v-btn>
                    </v-hover>
                    <!-- Attribution: https://www.flaticon.com/free-icons/discord" -->
                    <v-hover v-slot="{ hover }" v-if="!$vuetify.breakpoint.smAndDown">
                        <v-btn text large class="text-h6 mt-5" height="50" :style="{ 'color': hover ? '#5CE1E6' : '#BB86FC' }" @click="openDiscord">
                            <img :src="require(`./assets/discord${hover ? '_hover' : ''}.png`)" height="30"/>
                        </v-btn>
                    </v-hover>

                    <!-- Mobile menu -->
                    <v-hover v-slot="{ hover }" v-if="$vuetify.breakpoint.smAndDown">
                        <v-app-bar-nav-icon class="mr-0 mt-5" :style="{ 'color': hover ? '#5CE1E6' : '#BB86FC' }" @click.stop="drawer = !drawer" />
                    </v-hover>
                </v-app-bar>

                <!-- the main page as defined by the current route -->
                <v-main style="padding-top:0; height: 100vh;">
                    <router-view />
                </v-main>
            </div>

            <!-- Side nav for small browsers -->
            <v-navigation-drawer v-model="drawer" :permanent="false" app mini-variant mini-variant-width="60%" style="background-color: rgba(38,50,56,0.95)" v-if="$vuetify.breakpoint.smAndDown">
                <v-list class="pb-0">
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>
                                <v-hover v-slot="{ hover }">
                                    <v-btn text large class="text-h6 mt-5 text-left" height="50" :style="{ 'color': hover ? '#5CE1E6' : '#BB86FC' }" @click="openFoundingPaper">Founding Paper</v-btn>
                                </v-hover>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>
                                <v-list-item-title>
                                    <v-hover v-slot="{ hover }">
                                        <v-btn text large class="text-h6 mt-5 text-left" height="50" :style="{ 'color': hover ? '#5CE1E6' : '#BB86FC' }" @click="openDaoDocs">DAO Docs</v-btn>
                                    </v-hover>
                                </v-list-item-title>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <!-- Attribution: https://www.flaticon.com/free-icons/discord" -->
                            <v-list-item-title>
                                <v-hover v-slot="{ hover }">
                                    <v-btn text large class="text-h6 mt-5 text-left" height="50" :style="{ 'color': hover ? '#5CE1E6' : '#BB86FC' }" @click="openDiscord">
                                        <img :src="require(`./assets/discord${hover ? '_hover' : ''}.png`)" height="30"/>
                                    </v-btn>
                                </v-hover>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-navigation-drawer>
        </div>
    </v-app>
</template>

<script>
export default {
    name: 'App',

    data() {
        return {
            // Small menu visibility
            drawer: null,

            // Meta attributes
            title: 'Positive Sum (+Σ) DAO',
            siteName: 'Positive Sum (+Σ) DAO',
            description: 'Positive Sum is a peer-to-peer community of startups, founders, and startup community enablers who collaborate to help entrepreneurs and startups grow, and who capture that value across the entire community using Web3 as a platform.',
            image: require('@/assets/positivesumdao.jpg'),
            twitterName: '0xpsum',
        }
    },

    metaInfo() {
        return { 
            title: this.title,
            meta: [
                { property: 'og:type', content: 'website' },
                
                // Title
                { property: 'og:title', content: this.title },
                { property: 'og:site_name', content: this.siteName },

                // Description
                { name: 'description', content: this.description },
                { property: 'og:description', content: this.description },
                
                // Add twitter meta-tags
                { name: 'twitter:card', content: 'summary_large_image' },
                { name: 'twitter:site', content: '@' + this.twitterName },

                // Add image preview
                { name: 'og:image', content: this.image },
                { name: 'twitter:image', content: this.image },
                
                // Search engine robots config
                { name: 'robots', content: 'index,follow' } 
            ]
        }
    },

    mounted() {
        // Set the background video playback rate
        this.$refs.video.playbackRate = 0.5;
    },

    methods: {
        openDaoDocs() {
            window.open('https://positivesumdao.notion.site/');
        },

        openFoundingPaper() {
            window.open('https://docs.google.com/document/d/1ojEBTEGQImWtOGxiL-eocoLAj30kF-wY4KfkVJrOXoo/view');
        },

        openDiscord() {
            window.open('https://discord.gg/3dhddwGSMf');
        },
    }
};
</script>

<style scoped>
    .v-btn:not(.v-btn--round).v-size--large.logo {
        width: 50px;
        height: 50px;
        min-width: 50px;
        color: black;
        font-size: 30px;
    }

    button.v-btn--text {
        text-transform: none;
    }

    video {
        object-fit: cover;
        position: absolute;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 2;
        background: rgba(0,0,0,0.6);
    }

    .home-content {
        z-index: 3;
    }
</style>