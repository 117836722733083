/**
 * Main application navigation router configuration
 *
 * Setups the application routes and page guards
 * 
 * @file   Main application navigation router configuration
 * @author LeanCTO
 * @since  1.0.0
 * @copyright (c) 2022 All rights reserved.
 * 
 */

// Common includes used in this file
import Vue from "vue";
import Router from "vue-router";

// Main/default routes
import Home from '@/views/Home';
import NotFound from '@/views/NotFound';
import AboutUs from '@/views/AboutUs';

// Specific page component for each page
import Page000 from '@/views/Page000';
import Page001 from '@/views/Page001';

// Add the router to the vue component
Vue.use(Router);

// Setup the new router and configure the supported routes
const router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
  
    // Define our base routes
    routes: [
        // Setup a wildcard route to a 404 page
        { path: '/notfound', name: 'notFound', component: NotFound, meta: { title: 'Not Found - Positive Sum' } },
        { path: '*', redirect: 'notFound' },
    
        // About page
        { path: '/about', name:'aboutUs', component: AboutUs, meta: { title: 'About - Positive Sum' } },
        
        // Main home page
        { path: "/", name: "home", component: Home, meta: { title: 'Positive Sum' },
            children: [
                { path: '000', component: Page000, props: true, meta: { title: '000 - Positive Sum' } },
                { path: '001', component: Page001, props: true, meta: { title: '001 - Positive Sum' } },
            ],
        },
    ]
});

export default router;