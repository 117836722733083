<template>
    <span class="digit" :class="{ current, smAndDown: $vuetify.breakpoint.smAndDown }" v-if="isValid && !title">{{ date | date_output }} ▪ {{ days | two_digits }}:{{ hours | two_digits }}:{{ minutes | two_digits }}:{{ seconds | two_digits }}</span>
    <span class="digit" :class="{ current, smAndDown: $vuetify.breakpoint.smAndDown }" v-else-if="isValid && title">{{title}} ▪ {{ days | two_digits }}:{{ hours | two_digits }}:{{ minutes | two_digits }}:{{ seconds | two_digits }}</span>
    <span class="digit" :class="{ current, smAndDown: $vuetify.breakpoint.smAndDown }" v-else-if="!isValid && !title">{{ date | date_output }} ▪ Coming soon</span>
    <span class="digit" :class="{ current, smAndDown: $vuetify.breakpoint.smAndDown }" v-else-if="!isValid && title">{{title}} ▪ Coming soon</span>
</template>

<script>
export default {

    /* ready function will be here */

    props : {
        date: String,
        current: Boolean,
        title: String,
    },

    data() {
        return {
            now: Math.trunc((new Date()).getTime() / 1000),
            dateSecs: null,
        }
    },

    beforeMount() {
        this.dateSecs = Math.trunc(Date.parse(this.date) / 1000);
    },

    // Watch country changes and sync the region list
    watch: {
      date(newDate) {
          this.dateSecs = Math.trunc(Date.parse(newDate) / 1000);
      }
    },
    
    mounted() {
        window.setInterval(() => {
            this.now = Math.trunc((new Date()).getTime() / 1000);
        }, 1000);
    },

    // Custom output functions
    filters: {
        "date_output": (value) => {
            if (!value || !value.length) {
                return '??????????';
            }

            const options = { year: 'numeric', month: 'short', day: '2-digit' };
            const d = Math.trunc(Date.parse(value));
            return new Date(d).toLocaleDateString('en-NZ', options);
        },

        'two_digits': (value) => {
            if(value.toString().length <= 1) {
                return "0"+value.toString();
            }
            return value.toString();
        },
    },

    computed: {
        isValid() {
            const secs = this.seconds;
            const mins = this.mins;
            const hours = this.hours;
            const days = this.days;
            return (days !== "" && hours !== '' && mins !== '' && secs !== "" && !Number.isNaN(days) && !Number.isNaN(hours) && !Number.isNaN(mins) && !Number.isNaN(secs))
        },

        seconds() {
            return (this.dateSecs - this.now) % 60;
        },

        minutes() {
            return Math.trunc((this.dateSecs - this.now) / 60) % 60;
        },

        hours() {
            return Math.trunc((this.dateSecs - this.now) / 60 / 60) % 24;
        },

        days() {
            return Math.trunc((this.dateSecs - this.now) / 60 / 60 / 24);
        }
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Big+Shoulders+Display:400,600,700');

.countdowntimer {
    display: inline;
}

.digit {
    font-family: 'Big Shoulders Display', sans-serif;
    font-size: 40px;
    color: #545454;
    text-transform: uppercase;
    font-weight: 600;
}

.digit.current {
    color: white;
    font-size: 45px;
    font-weight: 700;
}

/* Small browser sizes */
.smAndDown .digit {
    font-size: 30px;
}

.smAndDown .digit.current {
    font-size: 35px;
}

</style>