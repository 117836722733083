/**
 * Main home page
 *
 * 
 * @file   Main home page
 * @author LeanCTO
 * @since  1.0.0
 * @copyright (c) 2022 All rights reserved.
 * 
 */
 <template>
    <v-container>
        <v-row class="text-center">
            <v-col cols="12">
                <h1>Not Found</h1>
                <a @click="$router.go(-1)">Back</a>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'NotFound',
}
</script>
