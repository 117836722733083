/**
 * Main application entry point
 *
 * Imports key libraries and does any necssary stup before instantiating the main app component
 * 
 * @file   Front-end view controller for the register page
 * @author LeanCTO
 * @since  1.0.0
 * @copyright (c) 2022 All rights reserved.
 * 
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router';
import vuetify from './plugins/vuetify';
import VueMeta from './plugins/vue-meta';

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  VueMeta,
  render: h => h(App),
}).$mount('#app');
