/**
 * Modal Popup Page - render individual items
 *
 * 
 * @file   Modal popup page
 * @author LeanCTO
 * @since  1.0.0
 * @copyright (c) 2022 All rights reserved.
 * 
 */
 <template>
    <Transition name="modal">
        <div v-if="show" class="modal-mask">
            <div class="modal-wrapper" :class="{ 'sm': $vuetify.breakpoint.smAndDown}">
                <v-container class="modal-container" fluid align="center" justify="center">
                    <v-row class="modal-header text-center">
                        <v-col cols="12">
                            <slot name="header">
                                <a class="modal-default-button" @click="$emit('close')">╳</a>
                            </slot>
                        </v-col>
                    </v-row>
                    <v-row class="modal-body text-center">
                        <v-col cols="12">
                            <slot name="body"></slot>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </div>
    </Transition>
</template>

<script>
export default {
    name: 'ModalView',

    props: {
        show: Boolean,
        item: Object,
    },

    data() {
        return {
            escListener: null,
        }
    },

    created() {
        // Trap escape keys to close the modal
        let that = this;
        this.escListener = document.addEventListener('keyup', function (evt) {
            if (evt.keyCode === 27) {
                that.$emit('close');
            }
        });
    },
    destroy() {
        if (this.escListener) {
            document.removeEventListener(this.escListener);
        }
    },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Big+Shoulders+Display');

.modal-mask {
  /*position: fixed;*/
  position: absolute;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 15, 84, 0.95);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  background-color: rgba(0, 15, 84, 0.95);
  padding: 50px 30px;
}

.modal-wrapper.sm {
    padding: 50px 0;
}

.modal-container {
  margin: 0px auto;
  padding: 0;
  color: white;
  transition: all 0.3s ease;
  background-color: rgba(0, 15, 84, 0.95);
}

.modal-header h3 {
  margin-top: 0;
}

.modal-body {
  margin: 0 0 20px 0;
  padding-top: 40px;
}

a.modal-default-button {
    color: white;
    font-size: 80px;
    line-height: 30px;
    font-family: 'Big Shoulders Display', sans-serif;
}
/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
<style>
html, body {
    height: 100%;
    width: 100%;
    background-color: rgba(0, 15, 84, 0.95);
}
</style>
