/**
 * About us page
 *
 * 
 * @file   About us page
 * @author LeanCTO
 * @since  1.0.0
 * @copyright (c) 2022 All rights reserved.
 * 
 */
 <template>
    <v-container id="container">
        <Modal :show="true" @close="closeModal">
            <template #body>
                <p :class="{ 'sm': $vuetify.breakpoint.smAndDown}">positive sum is peer-to-peer community of startups, innovators, and change makers who help people create the future 10x faster than they can alone.</p>
                <p :class="{ 'sm': $vuetify.breakpoint.smAndDown}">We're an open and transparent community, operating as a DAO on the blockchain, meaning that the more people share their networks, their knowledge, and their know-how to help others succeed, the more the community as a whole becomes more successful and we all win together.</p>
                <p :class="{ 'sm': $vuetify.breakpoint.smAndDown}">We're not only here to offer startup support, we're actively building a unique IRL + URL community with events that blend startup, pop, and crypto- cultures to create something altogether different that people want to be part of.</p>
                <p :class="{ 'sm': $vuetify.breakpoint.smAndDown}">Our ultimate goal is help more people create positive impact in the world through game changing innovation, the power of entrepreneurship, and by helping each other create positive sum impact where we can all level up together.</p>
                <p :class="{ 'sm': $vuetify.breakpoint.smAndDown}" class="heart">❤️</p>
            </template>
        </Modal>
    </v-container>
</template>

<script>
import Modal from './Modal.vue';

export default {
    name: 'AboutUs',

    components: {
        Modal,
    },

    methods: {
        closeModal() {
            this.$router.replace('/');
        }
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Big+Shoulders+Display:400,600,700');
p {
    font-size: 25px;
    color: #ffffff;
    font-family: 'Big Shoulders Display', sans-serif;
    font-weight: 600;
    margin: 0 50px 30px 50px;
}

p.sm {
    margin: 0 0 30px 0;
}

p.heart {
    font-size: 50px;
    margin-top: 50px;
}
</style>